import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import newImage from "./components/images/new.png";

import "./results.css";

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error in Results component:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try again later.</h1>;
    }

    return this.props.children;
  }
}

const Results = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [needleAngle, setNeedleAngle] = useState(0); // State to track needle rotation angle
  const { domain } = useParams();
  const location = useLocation();
  const resultData = location.state ? location.state.result : null;

  // Extract data for display
  const ipAddresses = resultData?.ip_addresses?.ip_addresses;
  const ipv4Display =
    ipAddresses?.IPv4 &&
    Array.isArray(ipAddresses.IPv4) &&
    ipAddresses.IPv4.length > 0
      ? ipAddresses.IPv4.join(", ")
      : "No IPv4 data";
  const ipv6Display =
    ipAddresses?.IPv6 &&
    Array.isArray(ipAddresses.IPv6) &&
    ipAddresses.IPv6.length > 0
      ? ipAddresses.IPv6.join(", ")
      : "No IPv6 data";

  const hosting = resultData?.hosting || "unknown";
  const age = resultData?.age;
  const ssl_certificate = resultData?.ssl_certificate;
  const cms_vulnerability = resultData?.cms_vulnerabilities;
  const risks = resultData?.external_resources?.risks || {};
  const security = resultData?.security_headers;
  const redirection = resultData?.redirection;
  const WAF = resultData?.waf_detection;
  const riskScore = resultData?.risk_analysis?.risk_score || 0;

  // Determine risk level
  const setNeedle = (value) => {
    const minAngle = -90;
    const maxAngle = 90;
    const angle = minAngle + (value / 100) * (maxAngle - minAngle);
    setNeedleAngle(angle);
  };

  useEffect(() => {
    if (riskScore !== undefined) {
      console.log(`The risk score is ${riskScore}`); // Log the risk score

      if (riskScore <= 25) {
        setNeedle(25);
      } else if (riskScore > 25 && riskScore < 49) {
        setNeedle(40);
      } else if (riskScore >= 49 && riskScore < 60) {
        setNeedle(60);
      } else if (riskScore >= 75) {
        setNeedle(78);
      }
    }
  }, [riskScore]);

  const isHighRisk = riskScore >= 65;

  // Accordion toggle handler
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="container-fluid">
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg fixed-top" style={{ background: "#004777", width: '100%' }}>
  <div className="container-fluid">
    {/* Logo with link */}
    <a 
      className="navbar-brand" 
      href="https://malspy.com/" 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <img
        src={newImage} // Using the imported image
        alt="Malspy Logo"
        className="img-fluid"
        style={{ margin: '0' }}
      />
    </a>

    {/* Navbar toggler */}
    <button 
      className="navbar-toggler" 
      type="button" 
      data-bs-toggle="collapse" 
      data-bs-target="#navbarSupportedContent" 
      aria-controls="navbarSupportedContent" 
      aria-expanded="false" 
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon"></span>
    </button>

    {/* Navbar links */}
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
        <li className="nav-item me-3"><a className="nav-link active" aria-current="page" href="#">Products</a></li>
        <li className="nav-item me-3"><a className="nav-link" href="#">Solutions</a></li>
        <li className="nav-item me-3"><a className="nav-link" href="#">Plans & Pricing</a></li>
        <li className="nav-item dropdown me-3">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Partners</a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><hr className="dropdown-divider" /></li>
            <li><a className="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li className="nav-item me-3"><a className="nav-link" href="#">About Us</a></li>
      </ul>

      {/* Buttons */}
      <div className="d-flex ms-auto gap-2">
        <button className="btn btn-scan">Sign Up</button>
        {/* <button className="btn btn-login">Login</button> */}
      </div>
    </div>
  </div>
</nav>


      {/* Scan Result Section */}
      <div className="container mt-5" style={{ paddingTop: "20px" }}>
        <div className="row mt-5">
          <div className="col-md-3">
            <div className="meter-container" style={{ position: "relative", width: "383px", height: "206px" }}>
              <img className="meter-background" src="https://static.tildacdn.com/tild6464-3936-4136-a535-323966373933/scale.svg" alt="Meter Scale" style={{ width: "100%", position: "absolute", top: "0", left: "0" }} />
              <div className="needle-container" style={{ position: "absolute", width: "100%", height: "100%", top: "0", left: "0" }}>
                <img
                  className="needle"
                  src="https://static.tildacdn.com/tild3362-6664-4537-a130-343332326231/arrow.svg"
                  alt="Meter Needle"
                  style={{
                    position: "absolute",
                    width: "17px",
                    top: "50%",
                    left: "50%",
                    transformOrigin: "bottom center",
                    transform: `rotate(${needleAngle}deg)`, // Use needleAngle from state
                  }}
                />
              </div>
            </div>
            <p className="under-needle">Security Risk</p>
          </div>
          <div className="col-md-2"></div>

          <div className="col-md-6 mt-5">
  {resultData?.risk_analysis?.risk_score <= 25 ? (
    <p className="nomal">No Malicious Content Detected!</p>
  ) : resultData?.risk_analysis?.risk_score > 25 && resultData?.risk_analysis?.risk_score < 49 ? (
    <p className="nomal">There is a low risk at your website.</p>
  ) : resultData?.risk_analysis?.risk_score >= 49 && resultData?.risk_analysis?.risk_score < 60 ? (
    <p className="nomal">Moderate risk detected. Please review your website security.</p>
  ) : resultData?.risk_analysis?.risk_score >= 60 && resultData?.risk_analysis?.risk_score < 75 ? (
    <p className="nomal">High risk detected! Immediate action is recommended.</p>
  ) : (
    <p className="nomal">
     Critical risk detected! <br></br> <span style={{ color: "red" }}>Your website is highly vulnerable.</span> 
    </p>
  )}
  <button className="btn btn-scan" style={{ borderColor: "rgb(0, 191, 255)", fontSize: "25px", marginTop: "10px" }}>
    Protect My Website
  </button>
</div>

        </div>
      </div>

     {/* Scan Details Table */}
     <div className="container mt-5">
        <div className="row">
          <div className="col-12 mb-3">
          <p className="text-muted">
  <strong>Scanned site:</strong> 
  <a href={decodeURIComponent(domain)}>{decodeURIComponent(domain)}</a>
</p>

          </div>

          <div className="col-12">
          <table className="table table-bordered table-hover">
  <thead className="table-secondary">
    <tr>
      <th>
        <i className="fas fa-info-circle"></i> SiteScan Parameters
      </th>
      <th>
        <i className="fas fa-shield-alt"></i> Scan Details
      </th>
      <th>
        <i className="fas fa-check-circle"></i> Security Services Status
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <strong>IPv4 address:</strong>
        <ul>
          {ipv4Display.split(",").map((ip, index) => (
            <li key={index}>{ip.trim()}</li>
          ))}
        </ul>
      </td>
      <td>
        <strong>Javascript files:</strong> {risks.js ? <span>{risks.js}</span> : "Some JavaScript files may be potentially malicious."}
      </td>
      {!isHighRisk ? (
        <td>
          <i className="fas fa-check-circle text-success"></i> Quttera Labs{" "}
          <span className="text-success">Clean</span>
        </td>
      ) : (
        <td>
          <i className="fa-solid fa-circle-xmark text-danger"></i> Quttera Labs{" "}
          <span className="text-danger">High Risk</span>
        </td>
      )}
    </tr>
    <tr>
      <td>
        <strong>IPv6 address:</strong>
        <ul>
          {ipv6Display.split(",").map((ip, index) => (
            <li key={index}>{ip.trim()}</li>
          ))}
        </ul>
      </td>
      <td>
        <strong>Iframe included:</strong>{" "}
        {risks.iframes ? <span>{risks.iframes}</span> : "Some Iframes may be potentially malicious."}
      </td>
      {!isHighRisk ? (
        <td>
          <i className="fas fa-check-circle text-success"></i> Zeus Tracker{" "}
          <span className="text-success">Clean</span>
        </td>
      ) : (
        <td>
          <i className="fa-solid fa-circle-xmark text-danger"></i> Zeus Tracker{" "}
          <span className="text-danger">High Risk</span>
        </td>
      )}
    </tr>
    <tr>
      <td>
        <strong>Server:</strong> <span>{hosting}</span>
      </td>
      <td>
        <strong>Embedded objects</strong>{" "}
        {risks.objects ? <span>{risks.objects}</span> : "No Issues"}
      </td>
      {!isHighRisk ? (
        <td>
          <i className="fas fa-check-circle text-success"></i> Yandex Safebrowsing{" "}
          <span className="text-success">Clean</span>
        </td>
      ) : (
        <td>
          <i className="fa-solid fa-circle-xmark text-danger"></i> Yandex Safebrowsing{" "}
          <span className="text-danger">High Risk</span>
        </td>
      )}
    </tr>
    <tr>
      <td>
        <strong>Running on:</strong> {resultData?.language || "Unknown"}
      </td>
      <td>
        <strong>Domain age:</strong> {age}
      </td>
      {!isHighRisk ? (
        <td>
          <i className="fas fa-check-circle text-success"></i> MalwareDomainList{" "}
          <span className="text-success">Clean</span>
        </td>
      ) : (
        <td>
          <i className="fa-solid fa-circle-xmark text-danger"></i> MalwareDomainList{" "}
          <span className="text-danger">High Risk</span>
        </td>
      )}
    </tr>
    <tr>
      {resultData?.language === "WordPress" ? (
        <td>
          <strong>CMS:</strong> {cms_vulnerability}
        </td>
      ) : (
        <td></td>
      )}
     <td>
        <strong>SSL Certificate details:</strong>
        <div>
          <p>SSL Attached: {ssl_certificate?.attached ? "Yes" : "No"}</p>
          <p>Issuer: {ssl_certificate?.issuer || "Unknown"}</p>
        </div>
      </td>
      {!isHighRisk ? (
        <td>
          <i className="fas fa-check-circle text-success"></i> Phishtank{" "}
          <span className="text-success">Clean</span>
        </td>
      ) : (
        <td>
          <i className="fa-solid fa-circle-xmark text-danger"></i> Phishtank{" "}
          <span className="text-danger">High Risk</span>
        </td>
      )}
    </tr>
    <tr>
      <td></td>
      <td></td>
      
      {!isHighRisk ? (
        <td>
          <i className="fas fa-check-circle text-success"></i> Google{" "}
          <span className="text-success">Clean</span>
        </td>
      ) : (
        <td>
          <i className="fa-solid fa-circle-xmark text-danger"></i> Google{" "}
          <span className="text-danger">High Risk</span>
        </td>
      )}
    </tr>
    <tr>
      <td></td>
      <td></td>
      {!isHighRisk ? (
        <td>
          <i className="fas fa-check-circle text-success"></i> Stop Badware{" "}
          <span className="text-success">Clean</span>
        </td>
      ) : (
        <td>
          <i className="fa-solid fa-circle-xmark text-danger"></i> Stop Badware{" "}
          <span className="text-danger">High Risk</span>
        </td>
      )}
    </tr>
    <tr>
      <td></td>
      <td></td>
      {!isHighRisk ? (
        <td>
          <i className="fas fa-check-circle text-success"></i> URL HUB{" "}
          <span className="text-success">Clean</span>
        </td>
      ) : (
        <td>
          <i className="fa-solid fa-circle-xmark text-danger"></i> URL HUB{" "}
          <span className="text-danger">High Risk</span>
        </td>
      )}
    </tr>
    <tr>
      <td></td>
      <td></td>
      {!isHighRisk ? (
        <td>
          <i className="fas fa-check-circle text-success"></i> VirusTotal{" "}
          <span className="text-success">Clean</span>
        </td>
      ) : (
        <td>
          <i className="fa-solid fa-circle-xmark text-danger"></i> VirusTotal{" "}
          <span className="text-danger">High Risk</span>
        </td>
      )}
    </tr>
  </tbody>
</table>

          </div>
        </div>
      </div>

      {/* Accordion Section */}
      <div className="container mt-5">
        <div className="accordion" id="accordionExample">
          {/* Card 1: Scanned Files Analysis */}
          <div className="card">
            <div className="card-header" id="headingOne" style={{ backgroundColor: '#9799bb' }}>
              <h2 className="mb-0">
                <button
                  className="btn btn-link text-left text-white font-weight-bold"
                  type="button"
                  onClick={() => handleToggle(1)}
                  aria-expanded={activeIndex === 1 ? 'true' : 'false'}
                  aria-controls="collapseOne"
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  <i className="fas fa-chart-bar mr-2"></i> Scanned Files Analysis
                </button>
              </h2>
            </div>
            <div
              id="collapseOne"
              className={`collapse ${activeIndex === 1 ? 'show' : ''}`} // Corrected syntax
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div className="card-body">
              <p>
  <strong>Redirects to:</strong> <a href={redirection} target="_blank" rel="noopener noreferrer">{redirection}</a>
</p>
                <p> <strong>Headers :  </strong> {security}</p>
                <p> <strong>Web Application Firewall : </strong> {WAF}</p>

                <p></p>
              </div>
            </div>
          </div>

          {/* Card 2: Additional Information */}
          <div className="card mt-2">
            <div className="card-header" id="headingTwo" style={{ backgroundColor: '#9799bb' }}>
              <h2 className="mb-0">
                <button
                  className="btn btn-link text-left text-white font-weight-bold"
                  type="button"
                  onClick={() => handleToggle(2)}
                  aria-expanded={activeIndex === 2 ? 'true' : 'false'}
                  aria-controls="collapseTwo"
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  <i className="fas fa-info-circle mr-2"></i> Recommendations
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              className={`collapse ${activeIndex === 2 ? 'show' : ''}`} // Corrected syntax
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div className="card-body">
  <p><strong>Tips to improve security:</strong></p>
  <ul>
    {resultData.risk_analysis.recommendations.map((recommendation, index) => (
      <li key={index}>{recommendation}</li>
    ))}
  </ul>
</div>
            </div>
          </div>

        </div>
      </div>
      <div className="contact-section mt-5  p-4" style={{ backgroundColor: "#213163", color: "white", borderRadius: "10px", background: "linear-gradient(to bottom, #213163, #5b7abf)" }}>
  <div className="row justify-content-center align-items-stretch mt-4">
    {/* Form Section */}
    <div className="col-md-5 p-4 bg-transparent  rounded-lg d-flex flex-column">
      <h4 className="mb-4 text-center" style={{ fontWeight: "bold", color: "white", fontSize: "1.5rem" }}>Get in Touch</h4>
      <form className="d-flex flex-column flex-grow-1 justify-content-between">
        <div className="form-group mb-3">
          <label htmlFor="name" style={{ fontWeight: "bold", color: "white" }}>Full Name</label>
          <input type="text" className="form-control form-control-sm" id="name" placeholder="Enter your full name" required style={{ backgroundColor: "#f0f0f0", color: "#333" }} />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="email" style={{ fontWeight: "bold", color: "white" }}>Email Address</label>
          <input type="email" className="form-control form-control-sm" id="email" placeholder="Enter your email address" required style={{ backgroundColor: "#f0f0f0", color: "#333" }} />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="subject" style={{ fontWeight: "bold", color: "white" }}>Subject</label>
          <input type="text" className="form-control form-control-sm" id="subject" placeholder="Enter the subject" required style={{ backgroundColor: "#f0f0f0", color: "#333" }} />
        </div>
        <div className="form-group mb-4">
          <label htmlFor="message" style={{ fontWeight: "bold", color: "white" }}>Message</label>
          <textarea className="form-control form-control-sm" id="message" rows="5" placeholder="Your message here..." required style={{ backgroundColor: "#f0f0f0", color: "#333" }}></textarea>
        </div>
        <button type="submit" className="btn btn-primary btn-lg w-100">Send Message</button>
      </form>
    </div>

    {/* Map Section */}
    <div className="col-md-5 p-4  mt-4 mt-md-0 d-flex flex-column">
      <h4 className="mb-4 text-center " style={{ fontWeight: "bold", color: "#333", fontSize: "1.5rem",  color: "white" }}>Our Location</h4>
      <div className="map-container flex-grow-1" style={{ width: "100%", height: "100%", position: "relative", overflow: "hidden", borderRadius: "12px" }}>
        <iframe
          className="w-100 h-100"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8314.529353327636!2d85.0960009649049!3d25.596124127686934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f29937c52d4f05%3A0x831a0e05f607b270!2sPatna%2C%20Bihar%2C%20India!5e0!3m2!1sen!2s!4v1731499431466!5m2!1sen!2s"
          style={{ border: "none", borderRadius: "12px" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  </div>
</div>


    </div>
  );
};

export default Results;
